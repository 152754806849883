'use client';

import { isServer, QueryClient, defaultShouldDehydrateQuery } from '@tanstack/react-query';

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
      dehydrate: {
        // per default, only successful Queries are included,
        // this includes pending Queries as well
        shouldDehydrateQuery: (query) => defaultShouldDehydrateQuery(query) || query.state.status === 'pending',
      },
    },
  });
}
let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export interface PaginatedResults<T = unknown> {
  items: T[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
}

/**
 * Since our APIs largely use the same pagination structure, we can create a helper function to extract the next page number from the response data.
 * @param data
 */
export function getNextPageParam<T extends PaginatedResults>(result: Maybe<T>): number {
  const maxSize = result?.pages ?? Math.ceil((result?.total ?? 1) / (result?.size ?? 1)) ?? 1;
  return Math.min((result?.page ?? 1) + 1, maxSize);
}

/**
 * Since our APIs largely use the same pagination structure, we can create a helper function to extract the next page number from the response data.
 * @param data
 */
export function getPreviousPageParam<T extends PaginatedResults>(result: Maybe<T>): number {
  return Math.min((result?.page ?? 1) - 1, 1);
}
