import { create } from 'zustand';

export type ActiveMenuItem =
  | null
  | 'chat-history'
  | 'settings'
  | 'appsettings'
  | 'integrations'
  | 'personalize'
  | 'sources'
  | 'messages';

interface SideBarState {
  isMobileView: boolean;
  activeMenuItem: ActiveMenuItem;
  setActiveMenuItem: (item: ActiveMenuItem, isMobileView?: boolean) => void;
  triggerNewChat: boolean;
  setTriggerNewChat: () => void;
  setIsMobileView: (isMobileView: boolean) => void;
}

export const useSidebarStore = create<SideBarState>()((set) => ({
  isMobileView: false,
  activeMenuItem: null,
  triggerNewChat: false,
  setTriggerNewChat: () => {
    set(() => ({ triggerNewChat: true }));
    setTimeout(() => {
      set(() => ({ triggerNewChat: false }));
    }, 100);
  },
  setActiveMenuItem: (activeMenuItem: ActiveMenuItem, isMobileView: boolean = false) =>
    set(() => ({ activeMenuItem, isMobileView })),
  setIsMobileView: (isMobileView: boolean) => set(() => ({ isMobileView })),
}));
