import React, { useEffect, useState } from 'react';
import { ArrowDown, CircleCheck } from '@portal/icons';
import { X } from 'lucide-react';
import { QueuedIcon, renderIcon } from '~/components/ManageSource/RenderIcon';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Progress,
} from '@portal/ui';
import { DataObject } from '~/types/data-object';
import StatusComponent from '~/components/ManageSource/StatusComponent';
import { cn } from '~/lib/utils';

interface Activity {
  total_tasks: number;
  completed: number;
}

export const calculateProgress = (activities: Activity[]): { progress: number; status: string } => {
  const totalTasks = activities.reduce((acc, curr) => acc + (curr.total_tasks || 1), 0);
  const totalCompleted = activities.reduce((acc, curr) => acc + curr.completed, 0);
  const progress = totalTasks === 0 ? 0 : Math.round((totalCompleted / totalTasks) * 100);
  const status = totalTasks === 0 ? 'In queue' : totalCompleted === totalTasks ? 'Completed' : 'In progress';

  return { progress, status };
};

const SourceActivity = ({
  uploadingFiles,
  sources,
  onDeleteDataObject,
}: {
  uploadingFiles: any;
  sources: DataObject[];
  onDeleteDataObject: any;
}) => {
  const [view, setView] = useState(false);
  const [expand, setExpand] = useState(true);
  const [initialSource, setInitialSource] = useState<string[]>([]);
  const [activity, setActivity] = useState<DataObject[]>([]);

  useEffect(() => {
    setInitialSource(sources.map((source) => source.id));
  }, []);

  const clearSource = (clearIds: string[]) => {
    return setTimeout(() => {
      setActivity((prevActivity) => prevActivity.filter((item) => !clearIds.includes(item.id)));
      setInitialSource((prevInitialSource) => [...new Set([...prevInitialSource, ...clearIds])]);
    }, 5000);
  };

  useEffect(() => {
    const hasUploadingFiles = uploadingFiles.length > 0;
    const hasIncompleteSources = sources.some((source) => {
      const progress = calculateProgress(source.activities || []).progress;
      return progress < 100;
    });
    const viewState = hasUploadingFiles || hasIncompleteSources || activity.length > 0;
    if (view !== viewState) {
      setView(viewState);
    }
  }, [uploadingFiles, sources, view, activity]);

  useEffect(() => {
    const updateActivity = (sources: DataObject[], prevActivity: DataObject[]): DataObject[] => {
      return sources.filter((source) => {
        const progress = calculateProgress(source.activities || []).progress;
        if (progress < 100) {
          return true;
        }
        const wasActive = !initialSource.includes(source.id);
        const wasActivityPreviously = prevActivity.some((oldSource) => oldSource.id === source.id);
        return wasActive;
      });
    };
    setActivity((prevActivity) => updateActivity(sources, prevActivity));
  }, [uploadingFiles, sources, initialSource]);

  useEffect(() => {
    const clearIds = sources
      .filter((source) => {
        const progress = calculateProgress(source.activities || []).progress;
        return progress === 100 && !initialSource.includes(source.id);
      })
      .map((source) => source.id);

    if (clearIds.length > 0) {
      clearSource(clearIds);
    }
  }, [sources, activity, initialSource]);

  if (!view) {
    return null;
  }
  console.log({ activity, uploadingFiles });
  return (
    <div className="fixed z-50 bottom-4 right-4 w-[300px] border border-brand-secondary-grey/50 shadow-sm overflow-hidden rounded-lg text-xs">
      <div className="flex justify-between p-3 items-center bg-brand-secondary-grey">
        <div className="font-semibold text-sm">{'Adding Sources'}</div>
        <div className="flex gap-4">
          <ArrowDown
            className={cn('h-4 w-4 cursor-pointer', {
              'rotate-180': !expand,
            })}
            onClick={() => {
              setExpand(!expand);
            }}
          />
          <X className="h-4 w-4" />
        </div>
      </div>
      {expand && (
        <div className="p-5 flex flex-col gap-4 bg-white max-h-[244px] overflow-auto">
          {uploadingFiles?.map((source: any, index: number) => (
            <div className="flex gap-3 items-center" key={index}>
              <div>
                <QueuedIcon />
              </div>
              <div className="flex flex-col max-w-[150px] min-w-[150px]">
                <div className="font-semibold w-[150px] truncate">{source.name}</div>
                <div className="flex gap-1 flex-col text-black-700/50">
                  <div>{'Uploading..'}</div>
                  <Progress value={Number(source.progress)} />
                </div>
              </div>
            </div>
          ))}
          {activity.map((source: DataObject) => {
            const { activities = [] } = source;
            const { progress, status } = calculateProgress(activities);

            return (
              <div className="flex gap-3 items-center w-full cursor-pointer" key={source.id}>
                <div>{renderIcon(source, progress)}</div>
                <div className="flex flex-col flex-1 max-w-[150px] min-w-[150px]">
                  <div className="font-semibold truncate">{source.name}</div>
                  <StatusComponent status={status} source={source} progress={progress} />
                </div>
                {progress === 100 ? (
                  <CircleCheck className="text-[#4DAE5D] h-4 w-4 ml-auto" />
                ) : (
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        variant={'outline'}
                        size={'sm'}
                        className="p-[6px] leading-3 h-5 text-[10px] rounded-lg text-black-700/50"
                      >
                        Cancel
                      </Button>
                    </DialogTrigger>
                    <DialogPortal>
                      <DialogOverlay />
                      <DialogContent
                        className="flex flex-col gap-5 rounded-lg"
                        onOpenAutoFocus={(e) => e.preventDefault()}
                      >
                        <DialogHeader>
                          <DialogTitle>{'Cancel Upload'}</DialogTitle>
                        </DialogHeader>
                        <div className="px-6">
                          <div className="text-base text-brand-primary-black">
                            {'This will cancel uploading '}
                            <span className="px-1 font-bold">&quot;{source.name}&quot;</span>
                          </div>
                          <div className="text-sm text-brand-primary-black/40 mt-1">
                            {'Do you want to cancel uploading this source? This cannot be undone.'}
                          </div>
                          <DialogFooter className="mt-5 px-0">
                            <DialogTrigger asChild>
                              <Button size="sm" variant="outline" className="max-w-[72px]">
                                Cancel
                              </Button>
                            </DialogTrigger>

                            <Button
                              size="sm"
                              variant="destructive"
                              className="max-w-[72px]"
                              onClick={() => onDeleteDataObject(source.id)}
                            >
                              Delete
                            </Button>
                          </DialogFooter>
                        </div>
                      </DialogContent>
                    </DialogPortal>
                  </Dialog>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SourceActivity;
